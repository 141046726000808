<template>
  <div :visible.sync="innerVisible" class="licensing-popup subscribe-plans-wrapper" >
    <div v-if="stepChangePlanActive">
    <div class="plan-block-wrapper">
      
            <div class="contacts-switch-input">
              <el-switch
                v-model="item.active"
                active-color="#13ce66"
                inactive-color="#ccc"
                active-text="Yearly"
                inactive-text="Monthly"
                @change="updateQty"
              />
            </div>
            <div class="contacts-input-wrap">
             
              <input
                id="sale_rate"
                type="hidden"
                step="any"
                class="form-control"
                name="sale_rate"
                placeholder="Rate"
                :value="item.sale_rate"
                @keyup="updateRate"
                @change="updateRate"
              >
            </div>
            <div class="plans-list-section row">
              <div v-for="plandata in productmyplan" :key="plandata.plan_id" class="plan-item-inner">
                      <div class="plan-head">
                        <div class="plan-head-right">
                          <span class="plan-name">{{ plandata.name }} 
                          </span>
                          <!-- <button v-if="plandata.name.toLowerCase().trim()=='standard'" type="success"
                          class="btn-next-right"  @click="chooseFreeTrialBtn(plandata.plan_id)" >Enjoy free {{plandata.free_trial_days}}-days trial</button> -->
                           <span class="free-trial-span" v-if="productmyplan && productmyplan[0]['id']==plandata.plan_id">Current Plan</span>
                          <!-- <span class="free-trial-span" v-if="plandata.enquire_option==0 && plandata.free_trial_days>0">Enjoy free {{plandata.free_trial_days}}-days trial</span> -->
                          <span class="sale_total plan_val_span" v-if="plandata.enquire_option==0 && plandata.sale_discount_total!=plandata.sale_total">${{ plandata.sale_discount_total }}</span>   
                          <span class="sale_totalplan_val_span" style="text-decoration: line-through;" v-if="plandata.enquire_option==0 && plandata.sale_discount_total!=plandata.sale_total">${{ plandata.sale_total }}</span>
                          <span class="sale_total plan_val_span" v-else-if="plandata.enquire_option==0">${{ plandata.sale_total }}</span>
                          <span class="sale_total plan_val_span" v-if="plandata.enquire_option>0">Custom</span>
                        </div>
                      </div>
                      <ul>
                        <!-- <li>
                          <span class="el-icon-check" />
                          From <span class="plan_val_span">{{ startdate }} to {{ enddate }}</span>
                        </li> -->
                        <li>
                          <span class="el-icon-check" />
                          Contacts stored   <span class="plan_val_span" v-if="plandata.enquire_option==0">Up to {{ plandata.noofcontacts }}</span>
                        </li>
                        <li v-if="plandata.greetingcards>0">
                          <span class="el-icon-check" />
                          Free Greetings <span class="plan_val_span" v-if="plandata.enquire_option==0">Up to {{ plandata.greetingcards  }}</span> 
                        </li>
                        <li>
                          <span class="el-icon-check" />
                          Automatic reminders for upcoming important dates
                        </li>
                        <li>
                          <span class="el-icon-check" />
                          Schedule gifts and greetings

                        </li>
                        <li v-if="plandata.name.toLowerCase().trim()=='premium' || plandata.enquire_option>0">
                          <span class="el-icon-check" />
                          Pre-selected Gift Recommendation
                        </li>    
                        <li v-else>
                          <span class="el-icon-close" />
                          Pre-selected Gift Recommendation
                        </li>                    
                          <li v-if="plandata.annual_gifts>0 && plandata.name.toLowerCase().trim()=='premium' || plandata.enquire_option>0 ">
                          <span class="el-icon-check" />
                          <span class="plan_val_span" v-if="plandata.enquire_option==0">{{ plandata.annual_gifts   }}</span><span class="custom_plan" v-else>Custom</span> Free KalaTrace gift to send 
                        </li>
                        <li v-else>
                          <span class="el-icon-close" />
                          <span class="plan_val_span" v-if="plandata.enquire_option==0"></span> Free KalaTrace gift to send 
                        </li>  
                         <li v-if="plandata.name.toLowerCase().trim()=='premium' || plandata.enquire_option>0">
                          <span class="el-icon-check" />
                           Access to exclusive gifts / experiences 
                        </li>
                        <li v-else>
                          <span class="el-icon-close" />
                          Access to exclusive gifts / experiences 
                        </li>
                        <!-- <li>
                          <span class="el-icon-check" />
                          <span class="plan_val_span" v-if="plandata.enquire_option==0"> {{ plandata.noofemailsends }}  / Month</span><span class="plan_val_span" v-else>Custom</span> Email sending limit
                        </li> -->
                      </ul>
                      <!-- {{productmyplan[0]['id']}} -->
                      <!-- v-if="plandata.plan_id!=productmyplan[0]['id']" -->
                       
                       <div class="plan-foot" >
                        <span v-if="user_details && user_details.email!='' && productmyplan[0]['id']==plandata.plan_id">
                          <span class="btn-next-right">Already subscribed</span>
                        </span>
                        <span v-else>
                        <span v-if="plandata.is_trial==1">
                          <el-button
                          v-if="stepTabActive3===0 && stepTabActive4===0 && plandata.enquire_option==0"
                          type="success"
                          class="btn-next-right"
                          @click="chooseFreeTrialBtn(plandata.plan_id,plandata.pay_period_months)"                          
                        >Purchase <i class="el-icon-arrow-right el-icon-right" /></el-button> 
                        </span>
                        <span v-else>
                        <el-button
                          v-if="stepTabActive3===0 && stepTabActive4===0 && plandata.enquire_option==0 && productmyplan[0]['id']!=plandata.plan_id"
                          type="success"
                          class="btn-next-right"
                          @click="choosePlanBtn(plandata.plan_id,plandata.pay_period_months)"
                        >Purchase <i class="el-icon-arrow-right el-icon-right" /></el-button> 
                        </span>                       
                        <el-button
                          v-if="plandata.enquire_option==1"
                          type="success"
                          class="btn-next-right"
                          @click="enquireNowBtn(plandata.plan_id)"
                        >
                        Inquire Now <i class="el-icon-arrow-right el-icon-right" /></el-button>
                      </span>  
                      </div>

                      <!-- <span class="plan_val_span"></span>
                      <span class="plan_val_span">Date Range : {{ startdate }} - {{ enddate }}</span>
                      <span class="plan_val_span">Total No. of Contacts : {{ plandata.noofcontacts }}</span>
                      <span class="plan_val_span">Plan Price : ${{ plandata.single_seat_cost }}</span>
                      <span class="plan_val_span">Email Sending Limit : {{ plandata.noofemailsends }}  / {{ plandata.planbillcycle }} </span>
                      <span class="plan_val_span">Total Quote : <span class="sale_total plan_val_span">${{ plandata.sale_total }}</span></span> -->
                    </div>
              </div> 
      </div>
    </div>
    <span style="padding:1px;">&nbsp;</span>
    <div v-if="stepTabActive1" class="plan-block plans-list-wrap1">
      <div class="my-subscription-block-wrapper">
            <div class="my-subplan-wrapper" v-loading="loading">
              <div class="row" v-for="(item1, index) in productmyplan" :key="index">
                <div class="col-md-3">
                   <div class="plan-name-text">
                    Plan Details                    
                   </div>
                </div>
                <div class="col-md-12">
                  <div class="plan-head">
                    {{item1.name}}
                  </div>
                  <div class="plan-details">
                      <ul>
                        <li>
                          Contacts stored   <span class="plan_val_span">Up to {{ item1.sub_max_contacts }}</span>
                        </li>
                        <li v-if="item1.sub_max_greeting_cards>0">
                          Free Greetings <span class="plan_val_span">Up to {{ item1.sub_max_greeting_cards  }}</span> 
                        </li>
                        <li>
                          Automatic reminders for upcoming important dates

                        </li>
                        <li>
                          Schedule gifts and greetings

                        </li>
                        <li v-if="planName.toLowerCase().trim()=='premium' || item1.enquire_option>0">
                          Pre-selected Gift Recommendation
                        </li>
                        
                          <li v-if="item1.sub_max_annual_gifts>0 ">
                          <span class="plan_val_span">{{ item1.sub_max_annual_gifts   }}</span> Free KalaTrace gift to send 
                        </li>
                        <li v-if="planName.toLowerCase().trim()=='premium' || item1.enquire_option>0">
                          Access to exclusive gifts / experiences
                          
                        </li>                                        
                      </ul>
                      <span class="plan-sub-title" > 
                           <b> Period : {{formatDate(new Date(productmyplan[0].sub_starts_from))}} - {{formatDate((new Date(productmyplan[0].sub_ends_at)))}} </b>
                        </span>
                       <!-- <span class="plan-sub-title" > 
                            Period : {{datediff((currentdate),(new Date(productmyplan[0].sub_ends_at)))}}/{{datediff((new Date(productmyplan[0].sub_starts_from)),(new Date(productmyplan[0].sub_ends_at)))}} days remaining
                        </span> -->
                  </div>
                </div>
              </div>

            </div> 

      </div>
    </div>
    <el-dialog :title="' Contact us'" width="43%" :visible.sync="enquireFormVisible" class="preview-popp-wrap">      
      <plan-enquiry-form :FormVisible="true" :selectedplan="selectedRadioPlanId"></plan-enquiry-form>
      
    </el-dialog>

    <div v-if="subsctionFormActive">
    <div v-if="stepTabActive2" class="plan-block billing-info-block plans-review-section">
      <v-row>
        <v-col cols="12" md="6">
          <div class="plan-checkout-section">
            
            <div class="billing-contact">
              <h3><span class="num-span">1</span>Billing point of contact</h3>
              <span class="text-span">
                <b>{{ user_details?user_details.first_name+' '+user_details.last_name:'' }}</b>
              </span>
              <span class="text-span">{{ user_details?user_details.email:'' }}</span>
            </div>
            <el-divider />
            <div class="billing-address">
              <h3><span class="num-span">2</span>Billing Address</h3>
              <el-form
                ref="billing_address"
                :label-position="labelBillPosition"
                label-width="100px"
                :model="billing_address"
                :rules="rules"
                @submit.prevent="handleSubmit"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <el-form-item label="Street Address" prop="address">
                      <el-input
                        id="address"
                        v-model="billing_address.address"
                        v-validate="'required'"
                        name="address"
                      />
                    </el-form-item>
                    <el-form-item label="City" prop="city">
                      <el-input v-model="billing_address.city" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" md="6">
                    
                    <el-form-item label="Country" prop="country">
                      <el-select v-model="billing_address.country" placeholder="Select">
                      <el-option
                        v-for="(item,code) in countries"
                        :key="code"
                        :label="item"
                        :value="code">
                      </el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item label="State/Province" prop="state">
                      <!-- <el-input v-model="billing_address.state" name="billing_address.state" /> -->
                      <el-select v-model="billing_address.state" placeholder="Select" :name="'billing_address.state'">
                          <el-option
                            v-for="(state, index) in countryStates[billing_address.country]"
                            :key="index"
                            :label="state.default_name"
                            :value="state.code">
                          </el-option>
                        </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" md="12">
                    <el-form-item label="Zip/Postal Code" prop="zip">
                      <el-input
                        id="zip"
                        v-model="billing_address.zip"
                        v-validate="'numeric'"
                        name="zip"
                      />
                    </el-form-item>
                  </v-col>
                </v-row>
              </el-form>
            </div>
            <div class="billing-card">
              <h3><span class="num-span">3</span>Billing Card Info</h3>
              <!-- <div style="margin: 50px;" /> -->
              <h6>Select Payment Method</h6>
              <div class="plan-block billing-card-info-block contact-list-wrapper">
                <div v-if="paymentMethods.length === 1" class="mt-3 mb-3">&nbsp;</div>
                <div
                  v-show="paymentMethods.length == 0"
                  class
                >No payment methods to show , please add a card.</div>
                <div v-loading="loading" style="width: 100%;height:100%;" />
                <div v-show="paymentMethodsLoadStatus == 2 && paymentMethods.length > 0">
                  <template>
                    <div class="chart-wrapper-sync">
                      <section v-cloak id="js-grid-list" class="grid-list">
                        
                        <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Select</th>
                                <th scope="col">Card type</th>
                                <th scope="col">Last 4 digit</th>
                                <th scope="col">Expires on</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item1, index) in paymentMethods" :key="index" class="list-content-wrap">
                                <th scope="row"> <el-radio
                                  v-model="radioPaymethods"
                                  :label="item1.pay_key"
                                  :checked="paymentMethodSelected==item1.id"
                                  :value="paymentMethodSelected"
                                  @change="newFn(item1.id,item1.pay_key,item1.last_four,item1.brand)"
                                >&nbsp;</el-radio></th>
                                <td>{{ item1.brand }}</td>
                                <td>{{ item1.last_four }}</td>
                                <td>{{ item1.expires_on }}</td>
                                <td> <el-tooltip content="Remove" :open-delay="300" placement="bottom">
                                  <a @click.prevent="removePaymentMethod(item1.id)">
                                    <i class="el-icon-delete" style="font-size: 15px;color: black;" />
                                  </a>
                                </el-tooltip></td>
                              </tr>
                              
                            </tbody>
                          </table>

                        <!-- <div class="tool-bar">
                          <div class="list-header">
                            <span>&nbsp;</span>
                            <span class="head-col-span">
                              Select
                            </span>
                            <span class="head-col-span">
                              Card type
                            </span>
                            <span class="head-col-span">
                              Last 4 digit
                            </span>
                            <span class="head-col-span">
                              Expires on
                            </span>
                            <span class="head-col-span">
                              Actions
                            </span>
                          </div>
                        </div>
                        <div class="list">
                          <div class="list-body">
                            <div v-for="(item1, index) in paymentMethods" :key="index" class="list-content-wrap">
                              <span class="list-img-col list-method-col">
                                <el-radio
                                  v-model="radioPaymethods"
                                  :label="item1.pay_key"
                                  :checked="paymentMethodSelected==item1.id"
                                  :value="paymentMethodSelected"
                                  @change="newFn(item1.id,item1.pay_key,item1.last_four,item1.brand)"
                                >&nbsp;</el-radio>
                              </span>
                              <span class="list-method-col">{{ item1.brand }} </span>
                              <span class="list-co list-method-col">{{ item1.last_four }}</span>
                              <span class="list-col">{{ item1.expires_on }}</span>
                              <span class="list-col list-col-icon">
                                <el-tooltip content="Remove" :open-delay="300" placement="bottom">
                                  <a @click.prevent="removePaymentMethod(item1.id)">
                                    <i class="el-icon-delete" style="font-size: 15px;color: black;" />
                                  </a>
                                </el-tooltip>
                              </span>
                            </div>
                          </div>
                        </div> -->
                      </section>
                    </div>
                    <!-- <el-table
                      ref="multipleTable"
                      :data="paymentMethods"
                      class="border rounded row p-1"
                      style="width: 100%"
                      :class="{'text-light': paymentMethodSelected == id}"
                    >
                      <el-table-column label="Select" width="70" prop="pay_key">
                        <template slot-scope="scope">
                          <el-radio
                            v-model="radioPaymethods"
                            :label="scope.row.pay_key"
                            :checked="paymentMethodSelected==scope.row.id"
                            :value="paymentMethodSelected"
                            @change="newFn(scope.row.id,scope.row.pay_key,scope.row.last_four,scope.row.brand)"
                          >&nbsp;</el-radio>
                        </template>
                      </el-table-column>
                      <el-table-column prop="brand" label="Card Type" width="150" />
                      <el-table-column prop="last_four" label="Last 4 digit" width="150" />
                      <el-table-column prop="expires_on" label="Expires on" width="150" />
                      <el-table-column prop="id" label="Action" width="150">
                        <template slot-scope="scope">
                          <el-button
                            size="small"
                            type="bordered-orange"
                            @click="removePaymentMethod(scope.row.id)"
                          >
                            <i class="el-icon-delete" style="color" />
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table> -->
                  </template>
                </div>
                <el-divider />
                <h6>Add a New Payment Method</h6>
                <div class="payment-card-div" :class="{'active': cardElementActive == 1}">
                  <template>
                    <el-radio v-model="radio" label="2" @change="cardActive(1)">Add Card</el-radio>
                  </template>
                  <div v-if="cardElementActive" class="card-display">
                    
                    
                   
                    <el-divider />
                    <div id="card-element" />
                     <el-button
                      id="add-card-button"
                      type="primary"
                      @click="submitPaymentMethod()"
                    >Add your card</el-button>
                    <el-divider />
                  </div>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <div class="sale-total-span">
                  <span>Purchase Amount:</span>
                  <span class="purchase_amnt">${{ item.sale_discount_total }}</span>
                </div>
              </span>
            </div>
          </div>
        </v-col>
        <v-col cols="6" md="6" v-loading="loading">
          <div class="plan-review-section">
            <h3>Order Review</h3>
            <v-item>
              <v-card
                :color="active ? 'primary' : ''"
                class="d-flex align-center "
                light
                elevation="3"
                outlined
              >
                <v-scroll-y-transition>
                  <div class="plan-item-inner">
                    <div class="plan-head">
                    
                      <div class="plan-head-right">
                        <span class="plan-name">{{ planName }}</span>
                        <span class="sale_total plan_val_span" v-if="item.sale_discount_total!=item.sale_total">${{ item.sale_discount_total }}</span>
                        <span class="sale_total plan_val_span" v-else>${{ item.sale_total }}</span>
                      </div>
                    </div>
                    <ul>
                        <!-- <li>
                          <span class="el-icon-check" />
                          From <span class="plan_val_span">{{ startdate }} to {{ item.enddate }}</span>
                        </li> -->
                        <li>
                          <span class="el-icon-check" />
                          Contacts stored   <span class="plan_val_span" v-if="item.enquire_option==0">Up to {{ item.noofcontacts }}</span>
                        </li>
                        <li v-if="item.greetingcards>0">
                          <span class="el-icon-check" />
                          Free Greetings <span class="plan_val_span" v-if="item.enquire_option==0">Up to{{ item.greetingcards  }}</span> 
                        </li>
                        <li>
                          <span class="el-icon-check" />
                          Automatic reminders for upcoming important dates

                        </li>
                        <li>
                          <span class="el-icon-check" />
                          Schedule gifts and greetings

                        </li>
                        <li v-if="planName.toLowerCase().trim()=='premium' || item.enquire_option>0">
                          <span class="el-icon-check" />
                          Pre-selected Gift Recommendation
                        </li>
                        
                          <li v-if="item.annual_gifts>0 ">
                          <span class="el-icon-check" />
                          <span class="plan_val_span">{{ item.annual_gifts   }}</span> Free KalaTrace gift to send 
                        </li>
                        <li v-if="planName.toLowerCase().trim()=='premium' || item.enquire_option>0">
                          <span class="el-icon-check" />
                          Access to exclusive gifts / experiences
                        </li>
                        <!-- <li>
                          <span class="el-icon-check" />
                          <span class="plan_val_span"> {{ item.noofemailsends }}  / {{ item.planbillcycle }}</span> Email sending limit
                        </li> -->
                      </ul>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
            <v-row class="payment-btn-wrap">
              <v-col cols="12" md="12">
                <div class="payment-btn">
                  <el-button
                    v-if="stepTabActive2===1"
                    type="success"
                    class="btn-next-middle"
                    plain
                    @click="updatepurchaseform()"
                  >Purchase</el-button>
                </div>
              </v-col>
              <v-col cols="12" md="12">
                <div class="secure_message">
                  This is a secure SSL encrypted payment.
                  We do not store any card information.
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    

    <div v-if="stepTabActiveTrial" class="plan-block billing-info-block plans-review-section">
      <v-row>
        <v-col cols="12" md="6">
          <div class="plan-checkout-section">
            <div class="billing-contact">
              <h3><span class="num-span">1</span>Billing point of contact</h3>
              <span class="text-span">
                <b>{{ user_details?user_details.first_name+' '+user_details.last_name:'' }}</b>
              </span>
              <span class="text-span">{{ user_details?user_details.email:'' }}</span>
            </div>
            <el-divider />
            <div class="billing-address">
              <h3><span class="num-span">2</span>Billing Address</h3>
              <el-form
                ref="billing_address"
                :label-position="labelBillPosition"
                label-width="100px"
                :model="billing_address"
                :rules="rules"
                @submit.prevent="handleSubmit"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <el-form-item label="Street Address" prop="address">
                      <el-input
                        id="address"
                        v-model="billing_address.address"
                        v-validate="'required'"
                        name="address"
                      />
                    </el-form-item>
                    <el-form-item label="City" prop="city">
                      <el-input v-model="billing_address.city" />
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" md="6">
                    
                    <el-form-item label="Country" prop="country">
                      <el-select v-model="billing_address.country" placeholder="Select">
                      <el-option
                        v-for="(item,code) in countries"
                        :key="code"
                        :label="item"
                        :value="code">
                      </el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item label="State/Province" prop="state">
                      <!-- <el-input v-model="billing_address.state" name="billing_address.state" /> -->
                      <el-select v-model="billing_address.state" placeholder="Select" :name="'billing_address.state'">
                          <el-option
                            v-for="(state, index) in countryStates[billing_address.country]"
                            :key="index"
                            :label="state.default_name"
                            :value="state.code">
                          </el-option>
                        </el-select>
                    </el-form-item>
                  </v-col>
                  <v-col cols="12" md="12">
                    <el-form-item label="Zip/Postal Code" prop="zip">
                      <el-input
                        id="zip"
                        v-model="billing_address.zip"
                        v-validate="'numeric'"
                        name="zip"
                      />
                    </el-form-item>
                  </v-col>
                </v-row>
              </el-form>
            </div>
            
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="plan-review-section">
            <h3>Order Review</h3>
            <v-item>
              <v-card
                :color="active ? 'primary' : ''"
                class="d-flex align-center "
                light
                elevation="3"
                outlined
              >
                <v-scroll-y-transition>
                  <div class="plan-item-inner">
                    <div class="plan-head">
                     
                      <div class="plan-head-right">
                        <span class="plan-name">{{ planName }}</span>
                        <span class="sale_total plan_val_span">Free {{item.free_trial_days}}-days trial</span>
                        
                        <!-- <span class="sale_total plan_val_span" v-if="item.sale_discount_total!=item.sale_total">${{ item.sale_discount_total }}</span>
                        <span class="sale_total plan_val_span" v-else>${{ item.sale_total }}</span> -->
                      </div>
                    </div>
                    <ul>
                        <!-- <li>
                          <span class="el-icon-check" />
                          From <span class="plan_val_span">{{ startdate }} to {{ item.enddate }}</span>
                        </li> -->
                        <li>
                          <span class="el-icon-check" />
                          Contacts stored    <span class="plan_val_span" v-if="item.enquire_option==0">Up to {{ item.noofcontacts }}</span>
                        </li>
                        <li v-if="item.greetingcards>0">
                          <span class="el-icon-check" />
                          Free Greetings <span class="plan_val_span" v-if="item.enquire_option==0">Up to {{ item.greetingcards  }}</span> 
                        </li>
                        <li>
                          <span class="el-icon-check" />
                          Automatic reminders for upcoming important dates

                        </li>
                        <li>
                          <span class="el-icon-check" />
                          Schedule gifts and greetings

                        </li>
                        <li v-if="planName.toLowerCase().trim()=='premium' || item.enquire_option>0">
                          <span class="el-icon-check" />
                          Pre-selected Gift Recommendation
                        </li>
                        
                          <li v-if="planName.toLowerCase().trim()=='premium' && item.annual_gifts>0">
                          <span class="el-icon-check" />
                          <span class="plan_val_span">{{ item.annual_gifts   }}</span> Free KalaTrace gift to send 
                        </li>
                        <li v-if="planName.toLowerCase().trim()=='premium' || item.enquire_option>0">
                          <span class="el-icon-check" />
                          Access to exclusive gifts / experiences
                        </li>
                        <!-- <li>
                          <span class="el-icon-check" />
                          <span class="plan_val_span"> {{ item.noofemailsends }}  / {{ item.planbillcycle }}</span> Email sending limit
                        </li> -->
                      </ul>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
            <v-row class="payment-btn-wrap">
              <v-col cols="12" md="12">
                <div class="payment-btn">
                  <el-button
                    v-if="stepTabActive2===1"
                    type="success"
                    class="btn-next-middle"
                    plain
                    @click="updatepurchaseform()"
                  >Purchase</el-button>
                  <el-button
                    v-if="stepTabActiveTrial===1"
                    type="success"
                    class="btn-next-middle"
                    plain
                    @click="updatepurchaseform()"
                  >Submit</el-button>
                </div>
              </v-col>
              <!-- <v-col cols="12" md="12">
                <div class="secure_message">
                  This is a secure SSL encrypted payment.
                  We do not store any card information.
                </div>
              </v-col> -->
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="stepTabActive4" class="plan-block payment-success-info-block">
      <h3>Payment Success</h3>
      <p>Your {{ planName }} plan activated successfully! !</p>
      <v-item>
        <v-card
          :color="active ? 'primary' : ''"
          class="d-flex align-center "
          light
          height="200"
          elevation="3"
          outlined
        >
          <v-scroll-y-transition>
            <div class="plan-item-inner">
              <div class="plan-success-head">
                <span class="plan-name">{{ planName }}</span>
              </div>
              <ul>
                        <li>
                          <span class="el-icon-check" />
                          Contacts stored   <span class="plan_val_span">Up to {{ item.noofcontacts }}</span>
                        </li>
                        <li v-if="item.greetingcards>0"> 
                          <span class="el-icon-check" />
                          Free Greetings <span class="plan_val_span">Up to {{ item.greetingcards  }}</span> 
                        </li>
                        <li>
                          <span class="el-icon-check" />
                          Automatic reminders for upcoming important dates

                        </li>
                        <li>
                          <span class="el-icon-check" />
                          Schedule gifts and greetings

                        </li>
                        <li v-if="planName.toLowerCase().trim()=='premium' || item.enquire_option>0">
                          <span class="el-icon-check" />
                          Pre-selected Gift Recommendation
                        </li>
                        <li v-if="planName.toLowerCase().trim()=='premium' || item.enquire_option>0">
                          <span class="el-icon-check" />
                          Access to exclusive gifts / experiences
                        </li>
                        <li v-if="item.annual_gifts>0">
                          <span class="el-icon-check" />
                          <span class="plan_val_span">{{ item.annual_gifts   }}</span> Free Annual Gifts 
                        </li>
                        <li>
                          <span class="el-icon-check" />
                          <span class="plan_val_span"> {{ item.noofemailsends }}  / {{ item.planbillcycle }}</span> Email sending limit
                        </li>
                      </ul>
            </div>
          </v-scroll-y-transition>
        </v-card>
      </v-item>
      <el-button
        v-if="stepTabActive4===1"
        type="success"
        plain
        @click="visibleFn"
      >Done</el-button>
    </div>
     </div>
  </div>
</template>

<script>
  
export default {
   name: 'Addsubscription',
  props: {
            count: {
                type: String,
                default: '10'
            },
            planId: {
                type: String,
                default: ''
            },
            planTitle: String,
            planRoute: String,
            localeDirection: String,
            showRecentlyViewed: {
                type: String,
                default: 'false'
            },
            recentlyViewedTitle: String,
            noDataText: String,
            selectedplan:Number,
            page: {
                type: String,
                default: ''
            },
        },
  data() {
    Array.prototype.move = function(from, to) {
        this.splice(to, 0, this.splice(from, 1)[0]);
        return this;
    };
    const validateRequire = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(rule.message.charAt(0).toUpperCase()));
      } else {
        callback();
      }
    };
    return {
      rules: {
        address: [
          {
            required: true,
            message: 'Please enter a valid address',
            validator: validateRequire,
          },
        ],
        city: [
          {
            required: true,
            message: 'Please enter a valid city',
            validator: validateRequire,
          },
        ],
        state: [
          {
            required: true,
            message: 'Please enter a valid state',
            validator: validateRequire,
          },
        ],
        country: [
          {
            required: true,
            message: 'Please select country',
            validator: validateRequire,
          },
        ],
        zip: [
          {
            required: true,
            message: 'Please enter a valid zip code',
            validator: validateRequire,
          },
        ],
      },
      radioPaymethods: '',
      stripeAPIToken: "pk_test_51L9YCSIMa39OHsjN4SoULyWezbifw7gPuxi3bpSgCFFaZYi04IrgJ231bUA349nAypVADXegAbR2gzfB13QKDJ8w006MfvgMLi",
      radio: '1',
      stripe: '',
      elements: '',
      card: '',
      intentToken: '',

      name: '',
      addPaymentStatus: 0,
      addPaymentStatusError: '',

      paymentMethods: [],
      paymentMethodsLoadStatus: 0,
      paymentMethodSelected: {},
      animated: true,
      radioPlanType: 'license',
      selectedPlan: 'price_1H7H6THwAIN2ybb8LqyhIUE6',
      selectedPlanId:0,
      dialogVisible: false,
      stepActive: 0,
      activeName: '',
      planName: '',
      stepTabActive1: 1,
      stepTabActive2: 0,
      stepTabActive3: 0,
      stepTabActive4: 0,
      stepTabActiveTrial: 0,
      productplans: {},
      productplansarry: {},
      productmyplan: {},
      selectedRadioPlanId:1,
      item: {
        noofcontacts: 0,
        noofemailsends: 0,
        sale_rate: 1,
        sale_total: 0,
        no_of_pack: 1,
        total_license: 0,
        single_seat_cost: 0,
        pay_period_months: 1,
        maximum_contacts: 0,
        maximum_email_sends: 0,
        extra_email_price: 0,
        planbillcycle: 'month',
        active: 0,
        annualprice: 0,
        monthlyprice: 0,
        free_trial_days:0,
      },
      planitems: {},
      user_details: { name: '', email: '' },
      labelBillPosition: 'top',
      billing_address: {
        address: '',
        city: '',
        state: '',
        country: 'US',
        zip: '',
      },
      countryStates:[],
      last_four: '',
      brand: '',
      subscribed: false,
      innerVisible: true,
      submitted: false,
      cardElementActive: 0,
      id: '',
      loading: false,
      minimum_contacts: 10,
      maximum_contacts: 10000000,
      startdate: '',
      enddate: '',
      noofcontacts: 10,
      steps: 10,
      enquireFormVisible:false, 
      subsctionFormActive:false,
      stepChangePlanActive:false,
      selectedPlanRadio:''
    };
  },  
  mounted() {
      
    this.loading= true;
    this.user_details = {email:''};
    console.log(this.item, 'this.planName', this.planName);
    let queryParams = new URLSearchParams(window.location.search);
    this.selectedPlanId = queryParams && queryParams.get('selectedplan');
    this.selectedperiods = queryParams && queryParams.get('periods');
    this.success = queryParams && queryParams.get('success');
    this.is_trial = queryParams && queryParams.get('is_trial');

    
    this.currentdate =  new Date()
    
    console.log(this.selectedplan, 'selectedplan');
    this.loadMyProductPlan();
    
    
    
  },

  methods: {
    
    formatDate(date) {
      var year = date.getFullYear().toString();
      var month = (date.getMonth() + 101).toString().substring(1);
      var day = (date.getDate() + 100).toString().substring(1);
      return month + '/' + day + '/' + year;
    },
   
   
   
    loadAllProductPlans() {
        var vparam = {
            mincontacts: this.noofcontacts,
          };
          let url = `${this.$root.baseUrl}/getProductPlans`;
          console.log(url, 'url');
          console.log(document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
          this.$http.post(url, {
              // 'planid':this.selectedPlanId,
              'mincontacts': this.noofcontacts,
              '_token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          })
          .then(response => {            
              console.log('response', response);
              this.productplans = response.data.data;
              this.user_details = response.data.user;
              var user_email =  this.user_details ? this.user_details.email : '';
              if(user_email!=''){
                this.loadMyProductPlan();
                setTimeout(() => {
                    // console.log('this.productmyplan=>55',this.productmyplan['is_trial']);
                    if(this.productmyplan['is_trial']==0){
                      // this.productplans.splice(this.productplans.findIndex(e => e.is_trial === 1),1);
                    }
                    this.loading = false;
                    console.log('this.productplans=>55',this.productplans);                    
                  }, 2000);              
              }
              this.loading = false;
              this.productplansarry = response.data.data;
            // setTimeout(() => {
              this.UpdateQty();
            //  }, 300,this);
            
            console.log(this.productplansarry, 'this.productplans2222222222');
            // if(this.selectedPlanId>0 &&  this.selectedPlanId!=null){
            //   this.next(this.selectedPlanId);
            //   this.selectedPlanRadio = this.selectedPlanId;
            // }
           
            var d = new Date(this.startdate);
            if(this.selectedperiods>1){
              d = new Date(d.setDate(d.getDate() + 365));
            }else{
              d = new Date(d.setDate(d.getDate() + 30));
            }
            d = new Date(d.setDate(d.getDate() - 1));

            this.enddate = this.formatDate(d); 
            console.log(this.startdate, 'startdate');
            console.log(this.enddate, 'enddate122');
          })
          .catch(error => {
              console.log('error.something_went_wrong', error);
          })
    },
    loadMyProductPlan() {
        var vparam = {
            mincontacts: this.noofcontacts,
          };
          let url = `${this.$root.baseUrl}/admin/customers/CustomercurrentPlan`;
          console.log(url, 'url');
          console.log(document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
          this.$http.post(url, {
              'id': this.selectedplan,
              '_token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          })
          .then(response => {
            this.loading = false;
            console.log('response', response);
            this.productmyplan = response.data.data;
            console.log('response this.productmyplan', this.productmyplan.length);
            if (this.productmyplan > 0) {
                console.log(this.productmyplan);
            } else {
                console.log('no records');
            }
          })
          .catch(error => {
              console.log('error.something_went_wrong', error);
          })
    },
    UpdateQty() {
      
      console.log('this.productplans',this.productplans);
        this.productplans.filter(item => {
            if (item.unit_amount > 0) {
              console.log(this.noofcontacts, 'itemitemitem===item', item);
              item.no_of_pack = Math.round((this.noofcontacts / item.maximum_contacts));
            }
              item.noofemailsends = (parseInt(item.maximum_email_sends ) * 1);
            if (this.active === true) {
                item.planbillcycle = 'Year';
                // d = new Date(d.setMonth(d.getMonth() + 12));
                item.noofemailsends = ((item.maximum_email_sends * item.no_of_pack) );
                item.pay_period_months = 12;
                item.single_seat_cost = (item.annualprice) > 0 ? item.annualprice : item.pay_period_months;
                item.greetingcards = parseInt(item.greeting_cards);
                item.discount_val = (item.single_seat_cost.toFixed(2)*item.annual_discount/100)
                item.sale_discount_total =  (item.single_seat_cost+item.discount_val).toFixed(2);

              } else {
                item.single_seat_cost = item.monthlyprice;
                item.planbillcycle = 'Month';
                // d = new Date(d.setMonth(d.getMonth() + 1));
                item.noofemailsends = (item.maximum_email_sends * 1);
                item.pay_period_months = 1;
                // item.single_seat_cost = (item.monthlyprice) > 0 ? item.monthlyprice : item.pay_period_months;
                item.single_seat_cost = (item.monthlyprice);
                item.greetingcards =  parseInt(item.greeting_cards)*1;
                item.discount_val = (item.single_seat_cost.toFixed(2)*item.monthly_discount/100)
                console.log('item.monthly_discount',item.monthly_discount)
                console.log('item.discount_val',item.discount_val)
                item.sale_discount_total =  (item.single_seat_cost-item.discount_val).toFixed(2);
                console.log('item.sale_discount_total',item.sale_discount_total)
    
              }
              console.log('item.maximum_email_sends', item.maximum_email_sends);
              // d = new Date(d.setDate(d.getDate() - 1));
              // this.enddate = this.formatDate(d);
              // item.sale_total = (item.single_seat_cost * item.no_of_pack);
              item.sale_total = item.single_seat_cost.toFixed(2);
          });
          console.log('After', this.productplans);

    },
    
  },
};
</script>
<style scoped>
#card-element {
  height: 45px !important;
  padding: 12px 0;
}
</style>
