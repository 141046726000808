<template>
  <div>
    <div class="container">
      <div>
        <h1>Vue Email Editor (Demo)</h1>

        <button v-on:click="saveDesign">Save Design</button>
        <button v-on:click="exportHtml">Export HTML</button>
      </div>

      <EmailEditor
        :appearance="appearance"
        :min-height="minHeight"
        :project-id="projectId"
        :locale="locale"
        :tools="tools"
        :options="options"
        ref="emailEditor"
        v-on:load="editorLoaded"
        v-on:ready="editorReady"
      />
    </div>
  </div>
</template>

<script>
  

  export default {
    data() {
      return {
        minHeight: '1000px',
        locale: 'en',
        projectId: 0, // replace with your project id
        tools: {
          // disable image tool
          image: {
            enabled: false,
          },
        },
        options: {},
        appearance: {
          theme: 'dark',
          panels: {
            tools: {
              dock: 'right',
            },
          },
        },
      };
    },
    methods: {
      // called when the editor is created
      editorLoaded() {
        console.log('editorLoaded');
        // Pass your template JSON here
        // this.$refs.emailEditor.editor.loadDesign({});
      },
      // called when the editor has finished loading
      editorReady() {
        console.log('editorReady');
      },
      saveDesign() {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          console.log('saveDesign', design);
        });
      },
      exportHtml() {
        this.$refs.emailEditor.editor.exportHtml((data) => {
          console.log('exportHtml', data);
        });
      },
    },
  };
</script>