import $ from 'jquery';
import Vue from 'vue';
// import Vuetify from 'vuetify'

import slider from './components/shop/slider';
import axios  from 'axios';
import EmailEditor from 'vue-email-editor';

// Vue.component('EmailEditor', () => import('vue-email-editor'));

import demo from './components/demo';
import demotest from './components/demotest';
import emaileditor from './components/emaileditor';
import eeditor from './components/eeditor';
import customersubscription from './components/customersubscription';
// import connection_add_form from './components/contactaddform';
// import ecards from './components/ecards';

/**
 * Vue prototype.
 */
Vue.prototype.$http = axios;

// Vue.config.devtools = true

window.jQuery = window.$ = $;
window.Vue = Vue;

// window.eventBus = new Vue();

// window.axios = axios;

Vue.use(EmailEditor);
Vue.component('demo', demo);
Vue.component('demotest', demotest);
Vue.component('slider', slider);

// Vue.use(Vuetify)
// import 'vuetify/dist/vuetify.min.css'
// const vuetify = new Vuetify();
 

// window.jQuery(function() {

    // let $elApp = $('<div id="app1"><div></div></div>')

    // $('body').append($elApp)

//     window.app = new Vue({
//     el: $elApp.children().get(0),
//   })
  

Vue.component('eeditor', eeditor);

Vue.component('emaileditor', emaileditor);
Vue.component('customersubscription', customersubscription);
// Vue.component('connection-add-form', () => import('./components/add-form-1'));

// Vue.component('connectionaddform', connection_add_form);
// Vue.component('ecards', ecards);

$(function() {

    Vue.mixin({
        data: function() {
            return {
                baseUrl: window.location.origin,
            };
        },
    });


    app = new Vue({
    el: "#app1",
    // vuetify

    });

    window.app = app;

});
