<template>
  <div>    
    <div>
      <div class="page-header">
        <div class="page-title">
          <h1>
             <i onclick="window.location = '/admin/emailtemplates'" class="icon angle-left-icon back-link"></i>Email Templates
          </h1>
        </div> 
        <div class="page-action">
          <button v-on:click="saveDesign" class="btn btn-lg btn-primary">
            Save Email Template
          </button>
        </div>
      </div>
      <div class="row" style="padding-top:10px; padding-left:30px">
        <div class="control-group">
           <label for="name" class="required">Name</label> 
           <input v-model="name" class="control" required>
        </div>
        <div class="control-group">
            <label for="categories" class="required">Category</label> 
            <div class="selectdiv">
              <!-- <select v-model="categories" class="control" :required="true">
                <option 
                 v-for="option in categorieslist" 
                 v-bind:value="option.name"
                 :selected="option == '<the default value you want>'"
                >{{ option.name }}</option>
              </select> -->

              <treeselect v-model="value" :multiple="false" :options="optionss" :searchable="false"  :default-expand-level="Infinity" placeholder="Select category" :maxHeight=200 :disable-branch-nodes="true">
               <div slot="value-label" slot-scope="{ node }"><span v-if="parentobject" style="font-weight: 600;" >{{ parentobject.name }} <span class="arrow"></span></span>  {{ node.raw.name }}</div>
               </treeselect>

            </div>
            
        </div>
      </div>


      <EmailEditor
        :appearance="appearance"
        :min-height="minHeight"
        :project-id="projectId"
        :locale="locale"
        :tools="tools"
        :options="options"
        ref="emailEditor"
        v-on:load="editorLoaded"
        v-on:ready="editorReady"
        @change="saveDesign"
      />
    </div>
  </div>
</template>

<script>
   // import the component
  import Treeselect from '@riophae/vue-treeselect'

   // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {
    name: 'emaileditorapp',
    components: { Treeselect },
    props: [
      'csrfToken',
      'isedit',
      'emailtemplates',
    ],
    data() {
      return {
        id: 0,
        categories: '',
        name: '',
        designcontent: {},
        htmlcontent: [],
        minHeight: '1000px',
        locale: 'en',
        projectId: 71021, // replace with your project id
        tools: {
          // disable image tool
          image: {
            enabled: true,
          },
        },
        options: {},
        appearance: {
          theme: 'light',
          panels: {
            tools: {
              dock: 'left',
            },
          },
        },
        imagedata : '',
        categorieslist: [],
        value: null,
        parentobject: [],
        optionss: [ {
          id: 'a',
          label: 'a',
          children: [ {
            id: 'aa',
            label: 'aa',
          }, {
            id: 'ab',
            label: 'ab',
          } ],
        }, {
          id: 'b',
          label: 'b',
        }, {
          id: 'c',
          label: 'c',
        } ],
      };
    },
    watch: {
     value() {
      console.log(this.optionss, 'value', this.value);
      this.parentobject = [];
      if (this.value != undefined) {
        const result = this.recursiveSearch(this.optionss, this.value);
        if (result) {
          const resultparent = this.recursiveSearch(this.optionss, result.parent_id);
          this.parentobject = resultparent;
        }
      }
    },
  },
    created() {
      this.getEmailtemplateList();
    },
    methods: {
      recursiveSearch(arr, val) {
        console.log(val, 'recursiveSearch');
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === val) {
                return arr[i];
            }
            if (arr[i].children) {
                const result = this.recursiveSearch(arr[i].children, val);
                if (result) {
                    return result;
                }
            }
        }
        return null;
      },
      // called when the editor is created
      editorLoaded() {
        const _this = this
        console.log('editorLoaded');
        console.log(this.isedit, 'data');
        if (this.isedit === 'true') {
          var data = JSON.parse(this.emailtemplates);
          this.$refs.emailEditor.editor.loadDesign(data.designcontent);
          this.categories = data.categories;
          this.name = data.name;
          this.designcontent = data.designcontent; 
          this.id = data.id;
          setTimeout(() => {
            this.value = data.category_id;
          }, 3000);
        }
        let url = `${this.$root.baseUrl}/admin/emailtemplates/create`;
        this.$refs.emailEditor.editor.registerCallback('image', function(file, done) {
            //  // Do something to upload the image and return the URL of the uploaded image
            console.log(file, 'files'); 
            var filedata = new FormData();
            filedata.append('file', file.attachments[0]);
            let url = window.location.origin+`/admin/emailtemplates/uploadimage`;
            axios.post(url, filedata, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
            .then(response => {
                console.log('response', response);
                if (response.status == '200') {
                    const data = response.data.data;                   
                    _this.imagedata = '/images/emailtemplates/'+data ;
                    done({ progress: 100, url: window.location.origin+'/images/emailtemplates/' + data });
                } else {
                  console.log('error');
                }
            })
            .catch(error => {
                console.log(this.__('error.something_went_wrong'));
            })
          });
        // Pass your template JSON here
        // this.$refs.emailEditor.editor.loadDesign({});
      },
      // called when the editor has finished loading
      editorReady() {
        console.log('editorReady');
        console.log('data', this.emailtemplates);
      },
      saveDesign() {

        if(this.name === ''){
             this.$message.error({
                message: "Please enter name",
                type: 'error',
                duration: 5 * 1000,
              });
        } else if(this.value==='' || this.value === null){
           this.$message.error({
              message: "Please select the category",
              type: 'error',
              duration: 5 * 1000,
            });
        } else {
          this.$refs.emailEditor.editor.saveDesign((design) => {
            console.log('saveDesign', design);
            this.designcontent = design;
          });
          this.$refs.emailEditor.editor.exportHtml((data) => {
            this.htmlcontent = data.html;
          });
          let url = `${this.$root.baseUrl}/admin/emailtemplates/create`;
          setTimeout(() => {
              this.$http.post(url, {
                  'name': this.name,
                  'categories': this.categories,
                  'designcontent' : this.designcontent,
                  'htmlcontent'   : this.htmlcontent, 
                  'imagename'     : this.imagedata,
                  'id'            : this.id,
                  'category_id'   : this.value,
                  '_token': this.csrfToken.split("&#039;").join(""),
              })
              .then(response => {
                  console.log('response', response);
                  if (response.data.status == '200') {
                      console.log(response.data.status);
                      if (response.data.redirectionRoute) {
                        window.location.href = response.data.redirectionRoute;
                      }
                  } else {
                    if (response.data.redirectionRoute) {
                        window.location.href = response.data.redirectionRoute;
                    }
                  }
              })
              .catch(error => {
                  console.log(this.__('error.something_went_wrong'));
              })
          }, 2000);
        }

      },
      exportHtml() {
        this.$refs.emailEditor.editor.exportHtml((data) => {
          console.log('exportHtml', data);
        });
      },
      async getEmailtemplateList() {
        // this.loading = true;
        let url = `${this.$root.baseUrl}/admin/emailtemplatescategories/alltemplatescategories`;
          console.log('urlurl',url);      
          console.log('this.tempid',this.tempid);
           
        const response = await this.$http.post(url, {
          parentid: 0,
          '_token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        })        
          .then(result => {
            return (result);
          });
          console.log('responseresponse',response);
        if (response.hasOwnProperty('error')) {
          if (response.error === 'Unauthorized') {
            location.reload();
          }
        } else {
          const data = response.data;
          console.log('data emailtemplates',data);
          this.categorieslist = data;
          this.optionss = data;
          console.log('data',this.categorieslist);
          this.loading = false;
          setTimeout(() => {
            this.value = data.category_id;
          },2000);
        }
        this.loading = false;
      },
    },
  };
</script>
<style rel="stylesheet/scss" lang="scss">
iframe {
  min-height: 700px !important;
}
.arrow {
  display: inline-block;
  height: 0px;
  width: 0px;
  border-left: 7px solid black;
  border-top: 4px solid white;
  border-bottom: 5px solid white;
}
</style>