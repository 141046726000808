<template>
  <div data-app>
    <v-app>
      <v-container class="px-0" fluid> </v-container>
      <v-card flat color="transparent">
        <!-- <div>
          <vue-slider
            v-model="value1"
            :adsorb="true"
            :interval="10"
            :marks="ticksLabels"
            step="1"
            ticks="always"
            tick-size="4"
            :included="true"
          ></vue-slider>
          <h1>{{ value1 }}</h1>
        </div>
        <v-subheader>Tick labels</v-subheader> -->

        <!-- <div v-for='(option, index) in (newAttributes)' :key="index">
    {{option.key}} 
    <h4>{{getvalue(option.val)}}</h4>
    <div v-for='(option1, index1) in (option.key)' :key="index1">
      <h5>{{option1['question']}}</h5>

    {{option1}}
    <el-card>
          <v-card-text>
            <v-slider
              v-model="option1['labellist']"
              :tick-labels="option1['labellist']"
              :max="4"
              step="1"
              ticks="always"
              :tick-size="4"
              
            />
          </v-card-text>
        </el-card>

      
    </div>
  </div> -->

   <!-- <v-container fluid>
     
    <hr>
    {{row}}
    <v-radio-group
      v-model="row"
      row
    >
      <v-radio
        label="Option 1"
        value="radio-1"
      ></v-radio>
      <v-radio
        label="Option 2"
        value="radio-2"
      ></v-radio>
    </v-radio-group>
  </v-container>
  <v-container
    class="px-0"
    fluid
  >
  {{radioGroup}}
    <v-radio-group v-model="radioGroup">
      <v-radio
        v-for="n in 3"
        :key="n"
        :label="`Radio ${n}`"
        :value="n"
        v-model="selectedItem"
         v-on:change="itemType(n)"
      ></v-radio>
    </v-radio-group>
  </v-container> -->
        <div class="kalascores-form-wrapper" v-show="showDiv" v-loading="loading">
          <div v-for="(option, index) in questionslist" :key="index">
            <!-- <h4>{{ getvalue(index) }}</h4> 
             <hr> -->
             <!-- <el-collapse>
            <el-collapse-item :title="getvalue(index)" name="1"> -->

            <div v-for="(option1, index1) in option" :key="index1">
              <h4 class="mt-5 mb-3">
                {{ option1["slno"] }} ) {{ option1["question"] }}
              </h4>
              <!-- {{answerslist[option1['id']]['answer']}}
              {{answerslist[option1['id']]['values']}} -->


              <!-- {{answerArry[index1][option1['id']]}}
         
              {{ option1.labellist[index1]['answer'] }}
              {{ option1.labellist[index1]['values'] }} -->
              <el-card>
                <v-card-text :set="questionlist1 = 'questionlist-'+index+parseInt(index1 + 1)">           
                  <v-radio-group v-model="row" mandatory @change="getselectedvalues" row> 
                    <v-radio
                        v-for="(n,j) in option1.labellist"
                        :key="j+parseInt(index1 + 1)"
                        :label="`${n.answer}`"
                        :value="j+parseInt(index1 + 1)+'-'+JSON.stringify([{'val': n.values, 'quesid':option1['id'] }])"
                         color="primary"                         
                      ></v-radio>
                    
                  </v-radio-group>
                </v-card-text>
              </el-card>
            </div>
             <!-- </el-collapse-item>
             </el-collapse> -->
          </div>
        </div>

        <!-- <v-card-text>
          <v-slider
            v-model="fruits"
            :tick-labels="ticksLabels"
            :max="3"
            step="1"
            ticks="always"
            tick-size="4"
            track-color="green"
          ></v-slider>
        </v-card-text> -->
        
      </v-card>
    </v-app>
  </div>
</template>

<script>
// import VueSlider from 'vue-slider-component'

export default {
  //  components: {
  //   VueSlider
  // },

  name: "slider",
  props: ["questionnarirelist", "ticksLabels", "tickLabels","answerslist"],
  data() {
    return {
      quescnt:0,
      column: null,
        row: null,
       radioGroup: 1,
      selectedItem: '',
      questionlist1: [],     
      questionslist: this.questionnarirelist,
      newAttributes: [],
      answerArry: [],   
      showDiv:false ,
      loading: false,
    };
  },
  created(){     
     setTimeout(() => this.showDiv = true, 1000)
     this.loading = true
     this.showDiv = true
  },
  mounted() {
    
    console.log("questions mounted.");
    console.log(this.answerslist);

    let attributes = this.questionnarirelist;
    let keys = Object.keys(attributes);
    let newValues = [];
    let answerArry = [];

    keys.forEach((attrKey, i) => {
      // answerArry[attrKey] =
      console.log(attributes[attrKey]);
      let s = {};

      s["key"] = attributes[attrKey];
      // answerArry[attrKey][i]["answers"] = attributes[attrKey][i]['options'];
      s["val"] = attrKey;
      newValues.push(s);

      attributes[attrKey].forEach((element, j) => {
        

        // JSON.parse(element['options']).forEach((element1, j1) => {
        // let anss = {};
        // anss[element['id']] =   element1;
        // anss["qskey"] = element['id'];
        // answerArry.push(anss)
        // });
        console.log("element", element);
        let anss = {};
        let answerArrys = [];
        let jsondtaa = JSON.parse(element["options"]);
        Object.keys(jsondtaa).forEach(function (k, i) {
          
          console.log(k + " - " + JSON.stringify(jsondtaa[k]["answer"]));
          let obj = {};
          obj["ans"] = JSON.stringify(jsondtaa[k]["answer"]);
          obj["val"] = JSON.stringify(jsondtaa[k]["values"]);
          // anss["qskey"] = element['id'];
          answerArrys.push(obj);
        });
        // anss["ansssrs"] = answerArrys;
        // anss["qsid"] = element["id"];
        anss[element["id"]] = answerArrys

       answerArry.push(anss);
        // let anss = {};
        // anss[element['id']] =   JSON.parse(element['options']);
        // // anss["qskey"] = element['id'];
        // answerArry.push(anss)
        
      });
       
      
    });

    // newValues.forEach((key, j) => {
    //    let anss = {};
    //   anss["answer"] =   attributes[key]['options'];
    //   answerArry.push(anss);
    // })

    this.newAttributes = newValues;
    this.answerArry = answerArry;
    this.quescnt= answerArry.length;
    console.log("this.newAttributes", this.newAttributes);
    console.log("answerArry", answerArry);

    //  questionslist: (this.questionnarirelist) ,
    //  for (item in this.questionnarirelist) {
    //   console.log(item);
    //  }
    // this.questionnarirelist.filter(function(u) {
    //   console.log('u.attribute',u.attribute);
    // });
  },
  methods: {
    season(val) {
      return this.icons[val];
    },
    doSomething(eventType) {
      console.log(eventType);
    },
    getvalue(t) {
      if (t == "T") {
        return "Trust";
      } else if (t == "C") {
        return "Collaboration";
      } else if (t == "B") {
        return "Bonding";
      } else if (t == "L") {
        return "Length";
      } else if (t == "F") {
        return "Frequency";
      } else if (t == "R") {
        return "Reciprical";
      }
    },
    changeEvent(index) {
      // alert(index);
      var value;
      // value = this.questionnarirelist.filter(function(u) {
      //   return u.id === index;
      // });
      console.log(value);
    },
    itemType(payload) {
        this.selectedItem = payload.val
        console.log('this.selectedItem',this.selectedItem);
    },
    getselectedvalues() {
      console.log('radio button changes');
    },
  },
  
  computed: {

  },
  watch: {
        selectedItem (val) {
        console.log(val)
        //this.$store.commit('item/setIteminStore', payload)
      }
    },
};
</script>
